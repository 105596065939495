//@ts-nocheck

import { useEffect } from "react"
import fetch from "isomorphic-unfetch"
import Head from "next/head"

export async function getServerSideProps() {
  const res = await fetch("https://home.diagram.com")
  let html = await res.text()

  // Remove Webflow badge
  html = html.replace(/<a class="w-webflow-badge".+?<\/a>/g, "")

  // Extract CSS and JS links from HTML
  const cssRegex = /<link rel="stylesheet" href="(.+?)">/g
  const jsRegex = /<script src="(.+?)"><\/script>/g
  const cssLinks = []
  const jsLinks = []
  let match
  while ((match = cssRegex.exec(html)) !== null) {
    cssLinks.push(match[1])
  }
  while ((match = jsRegex.exec(html)) !== null) {
    jsLinks.push(match[1])
  }

  // Fetch CSS and JS files
  const css = await Promise.all(cssLinks.map((link) => fetch(link).then((res) => res.text())))
  const js = await Promise.all(jsLinks.map((link) => fetch(link).then((res) => res.text())))

  // Extract font URLs from CSS
  const fontRegex = /url\('?(.+?\.woff2?)'?\)/g
  const fontUrls = new Set()
  css.forEach((cssText) => {
    let match
    while ((match = fontRegex.exec(cssText)) !== null) {
      fontUrls.add(match[1])
    }
  })

  // Fetch fonts
  const fonts = await Promise.all(
    Array.from(fontUrls).map((url) => fetch(url).then((res) => res.buffer()))
  )

  // Extract OG tags from HTML
  const ogRegex = /<meta (property|name)="(og:)?(.+?)" content="(.+?)"/g
  const ogTags = {}
  while ((match = ogRegex.exec(html)) !== null) {
    ogTags[match[3]] = match[4]
  }

  console.log({ ogTags })

  return {
    props: {
      html,
      css,
      js,
      fonts,
      ogTags,
    },
  }
}

export default function Home({ html, css, js, fonts, ogTags }) {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"
    script.async = true
    document.body.appendChild(script)

    window.addEventListener("particlesLoaded", () => {
      window.particlesJS.load("particles-js", "path/to/particles.json", () => {
        console.log("particles.js loaded!")
      })
    })
  }, [])

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <title>Diagram · Design tools from the future.</title>
        <meta
          content="Unleash your creativity with Diagram's AI-powered design tools."
          name="description"
        />
        <meta content="Diagram · Design tools from the future." property="og:title" />
        <meta
          content="Unleash your creativity with Diagram's AI-powered design tools."
          property="og:description"
        />
        <meta
          content="https://uploads-ssl.webflow.com/6408bea3de5aef58b7e197d4/646d04561d71910fc71ef91a_open-graph-image-3.png"
          property="og:image"
        />
        <meta content="Diagram · Design tools from the future." property="twitter:title" />
        <meta
          content="Unleash your creativity with Diagram's AI-powered design tools."
          property="twitter:description"
        />
        <meta
          content="https://uploads-ssl.webflow.com/6408bea3de5aef58b7e197d4/646d04561d71910fc71ef91a_open-graph-image-3.png"
          property="twitter:image"
        />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="Webflow" name="generator" />
      </Head>
      <style>{css.join("\n")}</style>

      <div dangerouslySetInnerHTML={{ __html: html }} />
      {js.map((script, index) => (
        <script key={index} dangerouslySetInnerHTML={{ __html: script }} />
      ))}
      {fonts.map((font, index) => (
        <style
          key={index}
        >{`@font-face{font-family:"Font Name";src:url(data:font/woff2;base64,${font.toString(
          "base64"
        )}) format("woff2");}`}</style>
      ))}
      <div id="particles-js" />
    </>
  )
}

// import { Suspense } from "react"
// import Image from "next/image"
// import Link from "next/link"
// import Layout from "app/core/layouts/Layout"
// import { useCurrentUser } from "app/core/hooks/useCurrentUser"
// import logo from "public/logo.png"
// import { useMutation } from "@blitzjs/rpc"
// import { Routes, BlitzPage } from "@blitzjs/next"
// import { Button } from "ui"
// import HomePage from "static/Home"

// /*
//  * This file is just for a pleasant getting started page for your new app.
//  * You can delete everything in here and start from scratch if you like.
//  */

// const UserInfo = () => {
//   const currentUser = useCurrentUser()
//   // const [logoutMutation] = useMutation(logout)

//   if (currentUser) {
//     return (
//       <>
//         <button
//           className="button small"
//           onClick={async () => {
//             console.log("clicked!")
//             // await logoutMutation()
//           }}
//         >
//           Logout
//         </button>
//         <div>
//           User id: <code>{currentUser.id}</code>
//           <br />
//           User role: <code>{currentUser.role}</code>
//         </div>
//       </>
//     )
//   } else {
//     return (
//       <>
//         <Link href={"/api/auth/google"} className="button small">
//           <strong>Login</strong>
//           <Button />
//         </Link>
//       </>
//     )
//   }
// }

// const Home: BlitzPage = () => {
//   return (
//     //@ts-ignore
//     // <Layout title="Home">
//     <HomePage />
//     // <div>Hello World!</div>

//     // </Layout>
//   )
// }

// export default Home
